@import "_generated-font-mixins.scss";
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.more {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;

  width: 100%;
  margin: 0 auto;
  margin-top: 30px;

  color: #006bb8;

  &::before {
    @include icon-more;

    font-size: 17px;
    line-height: 1;
    color: inherit;
    transition: transform 0.3s ease;
  }

  &:hover {
    opacity: 0.7;
  }

  &.loading {
    &::before {
      animation: rotate 1s linear infinite;
    }
  }

  &.mobile {
    min-height: 40px;
    margin: 4px 2px 0;

    font-size: 14px;
    color: #0058c5;

    border: 1px solid #0058c5;
    border-radius: 4px;
  }
}
