
$icon-ad-block-transfer: "\ea01";
$icon-ad-booster: "\ea02";
$icon-ad-btn: "\ea03";
$icon-ad-colorize: "\ea04";
$icon-ad-edit: "\ea05";
$icon-ad-hide: "\ea06";
$icon-ad-pay: "\ea07";
$icon-ad-republish-free: "\ea08";
$icon-ad-republish: "\ea09";
$icon-ad-show: "\ea0a";
$icon-adversting-header: "\ea0b";
$icon-alarm-arrow: "\ea0c";
$icon-alarm-step: "\ea0d";
$icon-arrow-back: "\ea0e";
$icon-arrow-down: "\ea0f";
$icon-arrow-header: "\ea10";
$icon-arrow-left: "\ea11";
$icon-arrow-navigation-left: "\ea12";
$icon-arrow-navigation-right: "\ea13";
$icon-arrow-right: "\ea14";
$icon-arrow-small-left: "\ea15";
$icon-arrow-small-right: "\ea16";
$icon-arrow-up: "\ea17";
$icon-arrows-left: "\ea18";
$icon-arrows-right: "\ea19";
$icon-aside-btn: "\ea1a";
$icon-attention: "\ea1b";
$icon-auto-refresh: "\ea1c";
$icon-bargaining: "\ea1d";
$icon-bars: "\ea1e";
$icon-basic: "\ea1f";
$icon-bcrumb-separator: "\ea20";
$icon-blog: "\ea21";
$icon-booking-price: "\ea22";
$icon-booking-status: "\ea23";
$icon-booking-user: "\ea24";
$icon-booking: "\ea25";
$icon-bookings: "\ea26";
$icon-bookmark-header: "\ea27";
$icon-booster: "\ea28";
$icon-business-header: "\ea29";
$icon-business: "\ea2a";
$icon-calendar-icon: "\ea2b";
$icon-calendar-today: "\ea2c";
$icon-calendar: "\ea2d";
$icon-camera-2: "\ea2e";
$icon-camera-plus: "\ea2f";
$icon-camera: "\ea30";
$icon-cancel: "\ea31";
$icon-chat: "\ea32";
$icon-check-step: "\ea33";
$icon-check-thin: "\ea34";
$icon-check: "\ea35";
$icon-checkmark: "\ea36";
$icon-chevron-down: "\ea37";
$icon-choose-data: "\ea38";
$icon-choose-period: "\ea39";
$icon-cigarrete: "\ea3a";
$icon-clock-fill: "\ea3b";
$icon-clock: "\ea3c";
$icon-close-booking: "\ea3d";
$icon-close: "\ea3e";
$icon-confirm: "\ea3f";
$icon-contact-delete-user: "\ea40";
$icon-contacts: "\ea41";
$icon-copy-blue: "\ea42";
$icon-copy: "\ea43";
$icon-date-picker: "\ea44";
$icon-delete-number: "\ea45";
$icon-delivery-truck-1: "\ea46";
$icon-delivery-truck2: "\ea47";
$icon-dots-vertical: "\ea48";
$icon-down: "\ea49";
$icon-download: "\ea4a";
$icon-draft-add: "\ea4b";
$icon-energy: "\ea4c";
$icon-engine: "\ea4d";
$icon-error: "\ea4e";
$icon-essential: "\ea4f";
$icon-estate: "\ea50";
$icon-eye-fill: "\ea51";
$icon-eye: "\ea52";
$icon-facebook: "\ea53";
$icon-fill-heart: "\ea54";
$icon-filter-btn: "\ea55";
$icon-frame-17: "\ea56";
$icon-frame: "\ea57";
$icon-heart: "\ea58";
$icon-highlight: "\ea59";
$icon-history-actions: "\ea5a";
$icon-history-header: "\ea5b";
$icon-history-mob: "\ea5c";
$icon-history-no-items: "\ea5d";
$icon-history-two: "\ea5e";
$icon-history-view: "\ea5f";
$icon-history: "\ea60";
$icon-home-2: "\ea61";
$icon-home-3: "\ea62";
$icon-home: "\ea63";
$icon-icon-cog: "\ea64";
$icon-icon-minus: "\ea65";
$icon-icon-plus: "\ea66";
$icon-inactive-eye: "\ea67";
$icon-info-tip: "\ea68";
$icon-info: "\ea69";
$icon-instagram: "\ea6a";
$icon-key: "\ea6b";
$icon-label: "\ea6c";
$icon-lang: "\ea6d";
$icon-like: "\ea6e";
$icon-list: "\ea6f";
$icon-lite-package: "\ea70";
$icon-log-in: "\ea71";
$icon-log-out-header: "\ea72";
$icon-log-out: "\ea73";
$icon-mail-classic: "\ea74";
$icon-mail-two: "\ea75";
$icon-mail: "\ea76";
$icon-map-pin: "\ea77";
$icon-market-fill: "\ea78";
$icon-market: "\ea79";
$icon-marketplace: "\ea7a";
$icon-mastercard: "\ea7b";
$icon-message-booking: "\ea7c";
$icon-message: "\ea7d";
$icon-messages-header: "\ea7e";
$icon-minus-sign: "\ea7f";
$icon-minus: "\ea80";
$icon-mobile: "\ea81";
$icon-money: "\ea82";
$icon-moon: "\ea83";
$icon-more: "\ea84";
$icon-my-ads: "\ea85";
$icon-next: "\ea86";
$icon-notification-nav: "\ea87";
$icon-notification-two: "\ea88";
$icon-notifications-header: "\ea89";
$icon-notifications: "\ea8a";
$icon-orders-header: "\ea8b";
$icon-package: "\ea8c";
$icon-paperclip: "\ea8d";
$icon-payment: "\ea8e";
$icon-payments: "\ea8f";
$icon-pencil-edit: "\ea90";
$icon-pencil: "\ea91";
$icon-phone-call: "\ea92";
$icon-phone-classic: "\ea93";
$icon-phone-contact: "\ea94";
$icon-phone: "\ea95";
$icon-photo-camera: "\ea96";
$icon-photo-plus: "\ea97";
$icon-pin: "\ea98";
$icon-play-icon: "\ea99";
$icon-plus-bg: "\ea9a";
$icon-plus-package: "\ea9b";
$icon-plus-sign: "\ea9c";
$icon-plus: "\ea9d";
$icon-post-ad: "\ea9e";
$icon-previous: "\ea9f";
$icon-print-ad: "\eaa0";
$icon-profile-header: "\eaa1";
$icon-profile: "\eaa2";
$icon-question: "\eaa3";
$icon-refresh: "\eaa4";
$icon-registration: "\eaa5";
$icon-rejected-step: "\eaa6";
$icon-remove-account-request: "\eaa7";
$icon-remove: "\eaa8";
$icon-rent-calendar: "\eaa9";
$icon-returned: "\eaaa";
$icon-review-star: "\eaab";
$icon-search-btn: "\eaac";
$icon-search-close: "\eaad";
$icon-search-link: "\eaae";
$icon-search-out: "\eaaf";
$icon-search: "\eab0";
$icon-send: "\eab1";
$icon-settings-two: "\eab2";
$icon-settings: "\eab3";
$icon-share-facebook: "\eab4";
$icon-share: "\eab5";
$icon-shift: "\eab6";
$icon-star-fill: "\eab7";
$icon-star: "\eab8";
$icon-statistics-two: "\eab9";
$icon-statistics: "\eaba";
$icon-subscribe-filters: "\eabb";
$icon-subscription-remove: "\eabc";
$icon-subscriptions: "\eabd";
$icon-success: "\eabe";
$icon-symlink: "\eabf";
$icon-throughout: "\eac0";
$icon-tiktok: "\eac1";
$icon-time: "\eac2";
$icon-trash-can: "\eac3";
$icon-trash-item: "\eac4";
$icon-trash: "\eac5";
$icon-triangle: "\eac6";
$icon-turbo-package: "\eac7";
$icon-union: "\eac8";
$icon-up: "\eac9";
$icon-verified-user: "\eaca";
$icon-video: "\eacb";
$icon-views: "\eacc";
$icon-vimeo-icon: "\eacd";
$icon-visa: "\eace";
$icon-vk: "\eacf";
$icon-wallet-header: "\ead0";
$icon-wallet: "\ead1";
$icon-warning: "\ead2";
$icon-wide-heart: "\ead3";
$icon-widecard: "\ead4";
$icon-youtube-icon: "\ead5";


@mixin icon-base {
  font-family: '999-icon-font' !important;
  font-size: 24px;
  color: #000000; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
}


@mixin icon-ad-block-transfer {
  @include icon-base;
  content: $icon-ad-block-transfer;
}


@mixin icon-ad-booster {
  @include icon-base;
  content: $icon-ad-booster;
}


@mixin icon-ad-btn {
  @include icon-base;
  content: $icon-ad-btn;
}


@mixin icon-ad-colorize {
  @include icon-base;
  content: $icon-ad-colorize;
}


@mixin icon-ad-edit {
  @include icon-base;
  content: $icon-ad-edit;
}


@mixin icon-ad-hide {
  @include icon-base;
  content: $icon-ad-hide;
}


@mixin icon-ad-pay {
  @include icon-base;
  content: $icon-ad-pay;
}


@mixin icon-ad-republish-free {
  @include icon-base;
  content: $icon-ad-republish-free;
}


@mixin icon-ad-republish {
  @include icon-base;
  content: $icon-ad-republish;
}


@mixin icon-ad-show {
  @include icon-base;
  content: $icon-ad-show;
}


@mixin icon-adversting-header {
  @include icon-base;
  content: $icon-adversting-header;
}


@mixin icon-alarm-arrow {
  @include icon-base;
  content: $icon-alarm-arrow;
}


@mixin icon-alarm-step {
  @include icon-base;
  content: $icon-alarm-step;
}


@mixin icon-arrow-back {
  @include icon-base;
  content: $icon-arrow-back;
}


@mixin icon-arrow-down {
  @include icon-base;
  content: $icon-arrow-down;
}


@mixin icon-arrow-header {
  @include icon-base;
  content: $icon-arrow-header;
}


@mixin icon-arrow-left {
  @include icon-base;
  content: $icon-arrow-left;
}


@mixin icon-arrow-navigation-left {
  @include icon-base;
  content: $icon-arrow-navigation-left;
}


@mixin icon-arrow-navigation-right {
  @include icon-base;
  content: $icon-arrow-navigation-right;
}


@mixin icon-arrow-right {
  @include icon-base;
  content: $icon-arrow-right;
}


@mixin icon-arrow-small-left {
  @include icon-base;
  content: $icon-arrow-small-left;
}


@mixin icon-arrow-small-right {
  @include icon-base;
  content: $icon-arrow-small-right;
}


@mixin icon-arrow-up {
  @include icon-base;
  content: $icon-arrow-up;
}


@mixin icon-arrows-left {
  @include icon-base;
  content: $icon-arrows-left;
}


@mixin icon-arrows-right {
  @include icon-base;
  content: $icon-arrows-right;
}


@mixin icon-aside-btn {
  @include icon-base;
  content: $icon-aside-btn;
}


@mixin icon-attention {
  @include icon-base;
  content: $icon-attention;
}


@mixin icon-auto-refresh {
  @include icon-base;
  content: $icon-auto-refresh;
}


@mixin icon-bargaining {
  @include icon-base;
  content: $icon-bargaining;
}


@mixin icon-bars {
  @include icon-base;
  content: $icon-bars;
}


@mixin icon-basic {
  @include icon-base;
  content: $icon-basic;
}


@mixin icon-bcrumb-separator {
  @include icon-base;
  content: $icon-bcrumb-separator;
}


@mixin icon-blog {
  @include icon-base;
  content: $icon-blog;
}


@mixin icon-booking-price {
  @include icon-base;
  content: $icon-booking-price;
}


@mixin icon-booking-status {
  @include icon-base;
  content: $icon-booking-status;
}


@mixin icon-booking-user {
  @include icon-base;
  content: $icon-booking-user;
}


@mixin icon-booking {
  @include icon-base;
  content: $icon-booking;
}


@mixin icon-bookings {
  @include icon-base;
  content: $icon-bookings;
}


@mixin icon-bookmark-header {
  @include icon-base;
  content: $icon-bookmark-header;
}


@mixin icon-booster {
  @include icon-base;
  content: $icon-booster;
}


@mixin icon-business-header {
  @include icon-base;
  content: $icon-business-header;
}


@mixin icon-business {
  @include icon-base;
  content: $icon-business;
}


@mixin icon-calendar-icon {
  @include icon-base;
  content: $icon-calendar-icon;
}


@mixin icon-calendar-today {
  @include icon-base;
  content: $icon-calendar-today;
}


@mixin icon-calendar {
  @include icon-base;
  content: $icon-calendar;
}


@mixin icon-camera-2 {
  @include icon-base;
  content: $icon-camera-2;
}


@mixin icon-camera-plus {
  @include icon-base;
  content: $icon-camera-plus;
}


@mixin icon-camera {
  @include icon-base;
  content: $icon-camera;
}


@mixin icon-cancel {
  @include icon-base;
  content: $icon-cancel;
}


@mixin icon-chat {
  @include icon-base;
  content: $icon-chat;
}


@mixin icon-check-step {
  @include icon-base;
  content: $icon-check-step;
}


@mixin icon-check-thin {
  @include icon-base;
  content: $icon-check-thin;
}


@mixin icon-check {
  @include icon-base;
  content: $icon-check;
}


@mixin icon-checkmark {
  @include icon-base;
  content: $icon-checkmark;
}


@mixin icon-chevron-down {
  @include icon-base;
  content: $icon-chevron-down;
}


@mixin icon-choose-data {
  @include icon-base;
  content: $icon-choose-data;
}


@mixin icon-choose-period {
  @include icon-base;
  content: $icon-choose-period;
}


@mixin icon-cigarrete {
  @include icon-base;
  content: $icon-cigarrete;
}


@mixin icon-clock-fill {
  @include icon-base;
  content: $icon-clock-fill;
}


@mixin icon-clock {
  @include icon-base;
  content: $icon-clock;
}


@mixin icon-close-booking {
  @include icon-base;
  content: $icon-close-booking;
}


@mixin icon-close {
  @include icon-base;
  content: $icon-close;
}


@mixin icon-confirm {
  @include icon-base;
  content: $icon-confirm;
}


@mixin icon-contact-delete-user {
  @include icon-base;
  content: $icon-contact-delete-user;
}


@mixin icon-contacts {
  @include icon-base;
  content: $icon-contacts;
}


@mixin icon-copy-blue {
  @include icon-base;
  content: $icon-copy-blue;
}


@mixin icon-copy {
  @include icon-base;
  content: $icon-copy;
}


@mixin icon-date-picker {
  @include icon-base;
  content: $icon-date-picker;
}


@mixin icon-delete-number {
  @include icon-base;
  content: $icon-delete-number;
}


@mixin icon-delivery-truck-1 {
  @include icon-base;
  content: $icon-delivery-truck-1;
}


@mixin icon-delivery-truck2 {
  @include icon-base;
  content: $icon-delivery-truck2;
}


@mixin icon-dots-vertical {
  @include icon-base;
  content: $icon-dots-vertical;
}


@mixin icon-down {
  @include icon-base;
  content: $icon-down;
}


@mixin icon-download {
  @include icon-base;
  content: $icon-download;
}


@mixin icon-draft-add {
  @include icon-base;
  content: $icon-draft-add;
}


@mixin icon-energy {
  @include icon-base;
  content: $icon-energy;
}


@mixin icon-engine {
  @include icon-base;
  content: $icon-engine;
}


@mixin icon-error {
  @include icon-base;
  content: $icon-error;
}


@mixin icon-essential {
  @include icon-base;
  content: $icon-essential;
}


@mixin icon-estate {
  @include icon-base;
  content: $icon-estate;
}


@mixin icon-eye-fill {
  @include icon-base;
  content: $icon-eye-fill;
}


@mixin icon-eye {
  @include icon-base;
  content: $icon-eye;
}


@mixin icon-facebook {
  @include icon-base;
  content: $icon-facebook;
}


@mixin icon-fill-heart {
  @include icon-base;
  content: $icon-fill-heart;
}


@mixin icon-filter-btn {
  @include icon-base;
  content: $icon-filter-btn;
}


@mixin icon-frame-17 {
  @include icon-base;
  content: $icon-frame-17;
}


@mixin icon-frame {
  @include icon-base;
  content: $icon-frame;
}


@mixin icon-heart {
  @include icon-base;
  content: $icon-heart;
}


@mixin icon-highlight {
  @include icon-base;
  content: $icon-highlight;
}


@mixin icon-history-actions {
  @include icon-base;
  content: $icon-history-actions;
}


@mixin icon-history-header {
  @include icon-base;
  content: $icon-history-header;
}


@mixin icon-history-mob {
  @include icon-base;
  content: $icon-history-mob;
}


@mixin icon-history-no-items {
  @include icon-base;
  content: $icon-history-no-items;
}


@mixin icon-history-two {
  @include icon-base;
  content: $icon-history-two;
}


@mixin icon-history-view {
  @include icon-base;
  content: $icon-history-view;
}


@mixin icon-history {
  @include icon-base;
  content: $icon-history;
}


@mixin icon-home-2 {
  @include icon-base;
  content: $icon-home-2;
}


@mixin icon-home-3 {
  @include icon-base;
  content: $icon-home-3;
}


@mixin icon-home {
  @include icon-base;
  content: $icon-home;
}


@mixin icon-icon-cog {
  @include icon-base;
  content: $icon-icon-cog;
}


@mixin icon-icon-minus {
  @include icon-base;
  content: $icon-icon-minus;
}


@mixin icon-icon-plus {
  @include icon-base;
  content: $icon-icon-plus;
}


@mixin icon-inactive-eye {
  @include icon-base;
  content: $icon-inactive-eye;
}


@mixin icon-info-tip {
  @include icon-base;
  content: $icon-info-tip;
}


@mixin icon-info {
  @include icon-base;
  content: $icon-info;
}


@mixin icon-instagram {
  @include icon-base;
  content: $icon-instagram;
}


@mixin icon-key {
  @include icon-base;
  content: $icon-key;
}


@mixin icon-label {
  @include icon-base;
  content: $icon-label;
}


@mixin icon-lang {
  @include icon-base;
  content: $icon-lang;
}


@mixin icon-like {
  @include icon-base;
  content: $icon-like;
}


@mixin icon-list {
  @include icon-base;
  content: $icon-list;
}


@mixin icon-lite-package {
  @include icon-base;
  content: $icon-lite-package;
}


@mixin icon-log-in {
  @include icon-base;
  content: $icon-log-in;
}


@mixin icon-log-out-header {
  @include icon-base;
  content: $icon-log-out-header;
}


@mixin icon-log-out {
  @include icon-base;
  content: $icon-log-out;
}


@mixin icon-mail-classic {
  @include icon-base;
  content: $icon-mail-classic;
}


@mixin icon-mail-two {
  @include icon-base;
  content: $icon-mail-two;
}


@mixin icon-mail {
  @include icon-base;
  content: $icon-mail;
}


@mixin icon-map-pin {
  @include icon-base;
  content: $icon-map-pin;
}


@mixin icon-market-fill {
  @include icon-base;
  content: $icon-market-fill;
}


@mixin icon-market {
  @include icon-base;
  content: $icon-market;
}


@mixin icon-marketplace {
  @include icon-base;
  content: $icon-marketplace;
}


@mixin icon-mastercard {
  @include icon-base;
  content: $icon-mastercard;
}


@mixin icon-message-booking {
  @include icon-base;
  content: $icon-message-booking;
}


@mixin icon-message {
  @include icon-base;
  content: $icon-message;
}


@mixin icon-messages-header {
  @include icon-base;
  content: $icon-messages-header;
}


@mixin icon-minus-sign {
  @include icon-base;
  content: $icon-minus-sign;
}


@mixin icon-minus {
  @include icon-base;
  content: $icon-minus;
}


@mixin icon-mobile {
  @include icon-base;
  content: $icon-mobile;
}


@mixin icon-money {
  @include icon-base;
  content: $icon-money;
}


@mixin icon-moon {
  @include icon-base;
  content: $icon-moon;
}


@mixin icon-more {
  @include icon-base;
  content: $icon-more;
}


@mixin icon-my-ads {
  @include icon-base;
  content: $icon-my-ads;
}


@mixin icon-next {
  @include icon-base;
  content: $icon-next;
}


@mixin icon-notification-nav {
  @include icon-base;
  content: $icon-notification-nav;
}


@mixin icon-notification-two {
  @include icon-base;
  content: $icon-notification-two;
}


@mixin icon-notifications-header {
  @include icon-base;
  content: $icon-notifications-header;
}


@mixin icon-notifications {
  @include icon-base;
  content: $icon-notifications;
}


@mixin icon-orders-header {
  @include icon-base;
  content: $icon-orders-header;
}


@mixin icon-package {
  @include icon-base;
  content: $icon-package;
}


@mixin icon-paperclip {
  @include icon-base;
  content: $icon-paperclip;
}


@mixin icon-payment {
  @include icon-base;
  content: $icon-payment;
}


@mixin icon-payments {
  @include icon-base;
  content: $icon-payments;
}


@mixin icon-pencil-edit {
  @include icon-base;
  content: $icon-pencil-edit;
}


@mixin icon-pencil {
  @include icon-base;
  content: $icon-pencil;
}


@mixin icon-phone-call {
  @include icon-base;
  content: $icon-phone-call;
}


@mixin icon-phone-classic {
  @include icon-base;
  content: $icon-phone-classic;
}


@mixin icon-phone-contact {
  @include icon-base;
  content: $icon-phone-contact;
}


@mixin icon-phone {
  @include icon-base;
  content: $icon-phone;
}


@mixin icon-photo-camera {
  @include icon-base;
  content: $icon-photo-camera;
}


@mixin icon-photo-plus {
  @include icon-base;
  content: $icon-photo-plus;
}


@mixin icon-pin {
  @include icon-base;
  content: $icon-pin;
}


@mixin icon-play-icon {
  @include icon-base;
  content: $icon-play-icon;
}


@mixin icon-plus-bg {
  @include icon-base;
  content: $icon-plus-bg;
}


@mixin icon-plus-package {
  @include icon-base;
  content: $icon-plus-package;
}


@mixin icon-plus-sign {
  @include icon-base;
  content: $icon-plus-sign;
}


@mixin icon-plus {
  @include icon-base;
  content: $icon-plus;
}


@mixin icon-post-ad {
  @include icon-base;
  content: $icon-post-ad;
}


@mixin icon-previous {
  @include icon-base;
  content: $icon-previous;
}


@mixin icon-print-ad {
  @include icon-base;
  content: $icon-print-ad;
}


@mixin icon-profile-header {
  @include icon-base;
  content: $icon-profile-header;
}


@mixin icon-profile {
  @include icon-base;
  content: $icon-profile;
}


@mixin icon-question {
  @include icon-base;
  content: $icon-question;
}


@mixin icon-refresh {
  @include icon-base;
  content: $icon-refresh;
}


@mixin icon-registration {
  @include icon-base;
  content: $icon-registration;
}


@mixin icon-rejected-step {
  @include icon-base;
  content: $icon-rejected-step;
}


@mixin icon-remove-account-request {
  @include icon-base;
  content: $icon-remove-account-request;
}


@mixin icon-remove {
  @include icon-base;
  content: $icon-remove;
}


@mixin icon-rent-calendar {
  @include icon-base;
  content: $icon-rent-calendar;
}


@mixin icon-returned {
  @include icon-base;
  content: $icon-returned;
}


@mixin icon-review-star {
  @include icon-base;
  content: $icon-review-star;
}


@mixin icon-search-btn {
  @include icon-base;
  content: $icon-search-btn;
}


@mixin icon-search-close {
  @include icon-base;
  content: $icon-search-close;
}


@mixin icon-search-link {
  @include icon-base;
  content: $icon-search-link;
}


@mixin icon-search-out {
  @include icon-base;
  content: $icon-search-out;
}


@mixin icon-search {
  @include icon-base;
  content: $icon-search;
}


@mixin icon-send {
  @include icon-base;
  content: $icon-send;
}


@mixin icon-settings-two {
  @include icon-base;
  content: $icon-settings-two;
}


@mixin icon-settings {
  @include icon-base;
  content: $icon-settings;
}


@mixin icon-share-facebook {
  @include icon-base;
  content: $icon-share-facebook;
}


@mixin icon-share {
  @include icon-base;
  content: $icon-share;
}


@mixin icon-shift {
  @include icon-base;
  content: $icon-shift;
}


@mixin icon-star-fill {
  @include icon-base;
  content: $icon-star-fill;
}


@mixin icon-star {
  @include icon-base;
  content: $icon-star;
}


@mixin icon-statistics-two {
  @include icon-base;
  content: $icon-statistics-two;
}


@mixin icon-statistics {
  @include icon-base;
  content: $icon-statistics;
}


@mixin icon-subscribe-filters {
  @include icon-base;
  content: $icon-subscribe-filters;
}


@mixin icon-subscription-remove {
  @include icon-base;
  content: $icon-subscription-remove;
}


@mixin icon-subscriptions {
  @include icon-base;
  content: $icon-subscriptions;
}


@mixin icon-success {
  @include icon-base;
  content: $icon-success;
}


@mixin icon-symlink {
  @include icon-base;
  content: $icon-symlink;
}


@mixin icon-throughout {
  @include icon-base;
  content: $icon-throughout;
}


@mixin icon-tiktok {
  @include icon-base;
  content: $icon-tiktok;
}


@mixin icon-time {
  @include icon-base;
  content: $icon-time;
}


@mixin icon-trash-can {
  @include icon-base;
  content: $icon-trash-can;
}


@mixin icon-trash-item {
  @include icon-base;
  content: $icon-trash-item;
}


@mixin icon-trash {
  @include icon-base;
  content: $icon-trash;
}


@mixin icon-triangle {
  @include icon-base;
  content: $icon-triangle;
}


@mixin icon-turbo-package {
  @include icon-base;
  content: $icon-turbo-package;
}


@mixin icon-union {
  @include icon-base;
  content: $icon-union;
}


@mixin icon-up {
  @include icon-base;
  content: $icon-up;
}


@mixin icon-verified-user {
  @include icon-base;
  content: $icon-verified-user;
}


@mixin icon-video {
  @include icon-base;
  content: $icon-video;
}


@mixin icon-views {
  @include icon-base;
  content: $icon-views;
}


@mixin icon-vimeo-icon {
  @include icon-base;
  content: $icon-vimeo-icon;
}


@mixin icon-visa {
  @include icon-base;
  content: $icon-visa;
}


@mixin icon-vk {
  @include icon-base;
  content: $icon-vk;
}


@mixin icon-wallet-header {
  @include icon-base;
  content: $icon-wallet-header;
}


@mixin icon-wallet {
  @include icon-base;
  content: $icon-wallet;
}


@mixin icon-warning {
  @include icon-base;
  content: $icon-warning;
}


@mixin icon-wide-heart {
  @include icon-base;
  content: $icon-wide-heart;
}


@mixin icon-widecard {
  @include icon-base;
  content: $icon-widecard;
}


@mixin icon-youtube-icon {
  @include icon-base;
  content: $icon-youtube-icon;
}

